import { Controller } from "@hotwired/stimulus";
import Cleave from "cleave.js";

export default class extends Controller {
  static targets = ["formattedPrice", "pricePoint", "validationMessage", "destroyField", "defaultCheckbox"];

  connect() {
    this.initializeCleave();
    this.initializeDefaultPricePointValidation();
  }

  initializeCleave() {
    this.formattedPriceTargets.forEach((input) => {
      new Cleave(input, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: '.',
        delimiter: ',',
        prefix: '$'
      });
    });
  }

  initializeDefaultPricePointValidation() {
    this.defaultCheckboxTargets.forEach((checkbox) => {
      checkbox.addEventListener('click', () => {
        this.validateDefaultPricePointSelection();
      });
    });
  }

  validateDefaultPricePointSelection() {
    const defaultPricePointCount = this.defaultCheckboxTargets.filter(cb => cb.checked).length;
    let validationTuple = this.getValidationTuple(defaultPricePointCount);
    this.validationMessageTarget.classList.remove('valid', 'invalid');
    this.validationMessageTarget.classList.add(validationTuple.className);
    this.validationMessageTarget.innerHTML = validationTuple.message;
  }

  getValidationTuple(count) {
    if (count > 1) {
      return { valid: false, className: 'invalid', message: '<i class="fa fa-times-circle"></i> You can only set one default Price Point.' };
    } else if (count < 1) {
      return { valid: false, className: 'invalid', message: '<i class="fa fa-times-circle"></i> To save your camp successfully please set 1 default Price Point.' };
    } else {
      return { valid: true, className: 'valid', message: '<i class="fa fa-check-circle"></i> Success!' };
    }
  }

  addPricePoint(event) {
    event.preventDefault();
    const time = new Date().getTime();
    const addButton = event.currentTarget;
    const regexp = new RegExp(addButton.dataset.id, 'g');
    const template = addButton.dataset.fields.replace(regexp, time);
    addButton.insertAdjacentHTML('beforebegin', template);
    this.initializeCleave();
    this.initializeDefaultPricePointValidation();
  }

  removePricePoint(event) {
    event.preventDefault();
    const fieldset = event.currentTarget.closest("fieldset.price-point");
    const markToDelete = fieldset.querySelector("input.destroy-field");
    const fields = fieldset.querySelectorAll("input");
    fields.forEach(field => field.removeAttribute('required'));
    if (markToDelete) {
      markToDelete.value = "1";
    } else {
      console.error("No input.destroy-field found within the fieldset.");
    }
    fieldset.style.display = "none";
  }
}
